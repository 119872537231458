@import "./fonts/clash-display.css";
@import "./fonts/clash-grotesk.css";
@import "./fonts/font-awesome.min.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

:root {
  --maxWidth-none: "none";
  --maxWidth-lg: 1440px;
  --maxWidth-full: "100%";
  --spacing-px: "1px";
  --spacing-0: 0;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  --spacing-12: 3rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-32: 8rem;
  --spacing-48: 12rem;
  --spacing-64: 18rem;
  --fontFamily: Lato, "ClashDisplay", "ClashGrotesk", Montserrat, system-ui, -apple-system, "Cambria", "Helvetica Neue", "Times News Roman";
  --fontFamilyHeadings: "ClashDisplay-Medium", Lato, "Helvetica Neue", "Times News Roman";
  --fontFamilySubheadings: "ClashGrotesk-Regular", Lato, "Helvetica Neue", "Times News Roman";
  --fontFamilyHeadingsBold: "ClashDisplay-Semibold", Lato, "Helvetica Neue", "Times News Roman";
  --fontFamilyQuote: "ClashDisplay-Regular", Lato, "Helvetica Neue", "Times News Roman";
  --font-body: var(--fontFamily);
  --font-heading: var(--fontFamily);
  --fontWeight-normal: 400;
  --fontWeight-medium: 500;
  --fontWeight-semibold: 600;
  --fontWeight-bold: 700;
  --fontWeight-extrabold: 800;
  --fontWeight-black: 900;
  --fontSize-root: 16px;
  --lineHeight-none: 1;
  --lineHeight-tight: 1.1;
  --lineHeight-normal: 1.5;
  --lineHeight-relaxed: 1.625;

  --fontSize-0: 0.625rem;
  --fontSize-1: 0.875rem;
  --fontSize-2: 1.125rem;
  --fontSize-3: 1.5rem;
  --fontSize-4: 2.0rem;;
  --fontSize-5: 2.688rem;
  --fontSize-6: 3.562rem;
  --fontSize-7: 4.750rem;
  --fontSize-8: 6.312rem;
  --fontSize-normal: var(--fontSize-2);

  --color-background: #E5E5E5;
  --color-background-2: #f3f3f3;
  --color-razem-10: #FAFAFA;
  --color-razem-50: #FCE8F4;
  --color-razem-100: #FAD1EA;
  --color-razem-200: #F5A3D4;
  --color-razem-300: #F075BF;
  --color-razem-400: #EB47A9;
  --color-razem-500: #870F57;
  --color-razem-600: #B81476;
  --color-razem-700: #8A0F59;
  --color-razem-800: #5C0A3B;
  --color-razem-900: #2E051E;
  --color-razem-950: #17030F;
  --color-gray-400: #A89FA4;
  --color-gray-600: #564E52;
  --color-gray-800: #292427;
  --color-gray-900: #1C171A;

  --color-work-default: #ba0c68;
  --color-work-1: #ed1d24;
  --color-work-2: #fa5600;
  --color-work-3: #da205f;
  --color-work-4: #fb838f;
  --color-work-5: #86112e;
  --color-work-6: #f68d41;
  --color-work-7: #940032;
  --color-work-8: #da4670;
  --color-work-9: #ec9322;
  --color-work-10: #ed174f;

  --color-primary: var(--color-razem-500);
  --color-text: var(--color-gray-600);
  --color-text-light: #4f5969;
  --color-heading: var(--color-razem-700);
  --color-heading-black: var(--color-razem-10);
  --color-accent: #d1dce5;
}

/* HTML elements */

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  line-height: var(--lineHeight-normal);
  font-size: var(--fontSize-root);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: var(--font-body);
  font-size: var(--fontSize-1);
  color: var(--color-text);
}

body, html {
  width: 100%;
  margin: 0;
}

footer {
  padding: var(--spacing-6) var(--spacing-0);
}

hr {
  background: var(--color-accent);
  height: 1px;
  border: 0;
}

/* Heading */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  margin-top: var(--spacing-12);
  margin-bottom: var(--spacing-6);
  line-height: var(--lineHeight-tight);
  letter-spacing: -0.025em;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--fontWeight-bold);
  color: var(--color-heading);
}

h1 {
  font-weight: var(--fontWeight-black);
  font-size: var(--fontSize-6);
  color: var(--color-text);
}

h2 {
  font-size: var(--fontSize-5);
}

h3 {
  font-size: var(--fontSize-4);
}

h4 {
  font-size: var(--fontSize-3);
}

h5 {
  font-size: var(--fontSize-2);
}

h6 {
  font-size: var(--fontSize-1);
}

h1 > a {
  color: inherit;
  text-decoration: none;
}

h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  text-decoration: none;
  color: inherit;
}

/* Prose */

p {
  line-height: var(--lineHeight-relaxed);
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  margin: var(--spacing-0) var(--spacing-0) var(--spacing-8) var(--spacing-0);
  padding: var(--spacing-0);
}

ul,
ol {
  margin-left: var(--spacing-0);
  margin-right: var(--spacing-0);
  padding: var(--spacing-0);
  margin-bottom: var(--spacing-8);
  list-style-position: outside;
  list-style-image: none;
}

ul li,
ol li {
  padding-left: var(--spacing-0);
  margin-bottom: calc(var(--spacing-8) / 2);
}

li > p {
  margin-bottom: calc(var(--spacing-8) / 2);
}

li *:last-child {
  margin-bottom: var(--spacing-0);
}

li > ul {
  margin-left: var(--spacing-8);
  margin-top: calc(var(--spacing-8) / 2);
}

blockquote {
  color: var(--color-text-light);
  margin-left: calc(-1 * var(--spacing-6));
  margin-right: var(--spacing-8);
  padding: var(--spacing-0) var(--spacing-0) var(--spacing-0) var(--spacing-6);
  border-left: var(--spacing-1) solid var(--color-primary);
  font-size: var(--fontSize-2);
  font-style: italic;
  margin-bottom: var(--spacing-8);
}

blockquote > :last-child {
  margin-bottom: var(--spacing-0);
}

blockquote > ul,
blockquote > ol {
  list-style-position: inside;
}

table {
  width: 100%;
  margin-bottom: var(--spacing-8);
  border-collapse: collapse;
  border-spacing: 0.25rem;
}

table thead tr th {
  border-bottom: 1px solid var(--color-accent);
}

/* Link */

a {
  color: var(--color-primary);
  word-break: break-word;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

/* Custom classes */

.layout {
  margin: var(--spacing-0) auto;
  max-width: var(--maxWidth-lg);
  padding: var(--spacing-10) var(--spacing-5);
}

.global-wrapper[data-is-root-path="true"] .bio {
  margin-bottom: var(--spacing-20);
}

.global-header {
  margin-bottom: var(--spacing-12);
}

.main-heading {
  font-size: var(--fontSize-7);
  margin-bottom: var(--spacing-8);
}

.post-list-item {
  margin-bottom: var(--spacing-8);
  margin-top: var(--spacing-8);
}

.post-list-item p {
  margin-bottom: var(--spacing-0);
}

.post-list-item h2 {
  font-size: var(--fontSize-4);
  color: var(--color-primary);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-0);
}

.post-list-item header {
  margin-bottom: var(--spacing-4);
}

.header-link-home {
  font-weight: var(--fontWeight-bold);
  font-family: var(--font-heading);
  text-decoration: none;
  font-size: var(--fontSize-2);
}

.blog-post header h1 {
  margin: var(--spacing-0) var(--spacing-0) var(--spacing-4) var(--spacing-0);
}

.blog-post header p {
  font-size: var(--fontSize-2);
  font-family: var(--font-heading);
}

.blog-post-nav ul {
  margin: var(--spacing-0);
}

.gatsby-highlight {
  margin-bottom: var(--spacing-8);
}

/* Media queries */

@media (max-width: 42rem) {
  blockquote {
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-0) var(--spacing-4);
    margin-left: var(--spacing-0);
  }
  ul,
  ol {
    list-style-position: inside;
  }
}
